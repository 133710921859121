<template>
	<section class="license">
		<div class="container">
			<Breadcrumb :pages="pages" class="license__crumb" />

			<h1 class="license__title">{{ $t('license.terms_title') }}</h1>
			<div class="license__items">
				<div class="license__item">
					<div v-html="$t('license.terms_text')" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'

export default {
	name: 'Terms',
	components: { Breadcrumb },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.conditions')} - OWRealty`, {
			description: i18n.global.t('meta.termsDesc'),
			type: 'article',
			title: i18n.global.t('meta.termsTitle'),
			url: window.location.href
		})
	},
	computed: {
		pages() {
			return {
				first: {
					srcName: 'MainRoute',
					title: this.$t('general.home')
				},
				current: this.$t('other.termOfUse')
			}
		}
	}
}
</script>
